import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/article-page-layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Intro = makeShortcode("Intro");
const Body = makeShortcode("Body");
const Outro = makeShortcode("Outro");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Intro mdxType="Intro">
      <p>{`CSSの設計の話で、Tailwind CSSが良いんだよね〜って話をする流れで、Tailwind CSSを使うには、デザインをルール化して、例えば文字サイズとか余白とかを決めたパターンで作って、そういう風にできるといい感じなんだよねって話になるんだけれども、そういうことを言うと、よくある反応として、`}</p>
      <p>{`「でもウチはデザインカンプが渡されて来て、それを言ってみれば完璧に再現することを求められるから、そういうのは無理だな〜」`}</p>
      <p>{`みたいなやつがある。そういうのを見るたびに「その仕事の仕方がまず違うんじゃないんすかねー」って常々思うんだけど、この本にまさにそういう話が書いてあって、そうよなーって思ったのでそれについて書く。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://amzn.to/3uFfKyv",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`The Business of Expertise by David C. Baker`}</a></li>
      </ul>
    </Intro>
    <Body mdxType="Body">
      <h2 {...{
        "id": "ルールが統一されることで嬉しいと一番知っているのはその道の専門である実装者"
      }}>{`ルールが統一されることで嬉しいと一番知っているのはその道の専門である実装者`}</h2>
      <p>{`まず、デザインカンプが渡されてそれを完璧に再現云々という認識について、おおよそ、自分の経験上、そのようにルール立ててデザインを作ることが出来る人の割合というのは、そう多くはない。ランディングページみたいなペライチ系は、正直そのあたりは大して重要じゃないかと思うけど、コーポレートサイトみたいに、何度も使うようなコンポーネントで基本画面を組み立てるようなケースでは、このあたりを考えてくれないと、結構その後が辛い。`}</p>
      <p>{`デザインカンプで再現されているデザインを忠実に再現してほしいと考えるのは、まぁデザインカンプを作った人からすれば当然そう思うだろうけれども、そのデザインをした人が、完全にCSSのことを理解して、こういう風にしたら管理性もバッチリだし〜みたいなところまで考えられてるというのは、相当稀なケースなんじゃ無いだろうか。少なくとも自分はこれまでそうだった。そういうルールがしっかりしていると嬉しいことを、最も理解しているのは実装している人間なんじゃなかろうか。`}</p>
      <p>{`なので、デザイナーと一緒に考えていくんだぜみたいな話を`}<a parentName="p" {...{
          "href": "https://amzn.to/34tDMBY",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`自分の本の中`}</a>{`では書いたけれども、仕事の工程として、デザインがまずあり、その次の工程としてHTMLとCSSを書くという流れがあるというフローにのっとり、言われるがままに依頼を受け取るというスタンスが、技術的な面はおいておいて、まず、仕事を受ける側として望ましくないと考える。CSSを日々書いていて、CSSを書く専門として仕事をするのであれば、ここはその専門性を行使するチャンスなのに、`}</p>
      <p>{`「でもウチはデザインカンプが渡されて来て、それを言ってみれば完璧に再現することを求められるから、そういうのは無理だな〜」`}</p>
      <p>{`とか思う人は、その機会をふいにしているように自分は感じる。`}</p>
      <p>{`ちなみに自分はそういう余白なり文字サイズなりのルールは勝手に揃えていて、むしろそれでありがたがられてきたので、デザイナーとのコミュニケーションが疎みたいなケースでは、個人的にはそういうスタンスで仕事をすることを勧めたい。`}</p>
      <h2 {...{
        "id": "我々は何かの専門家として仕事をしてる"
      }}>{`我々は何かの専門家として仕事をしてる`}</h2>
      <p>{`例えば美容院に行くとする。そして「星野源みたいな髪型にしてください」と頼んだとする。ここで、美容師さんはそのまま星野源みたいな髪型にしてしまうのだろうか。経験のある美容師さんであれば、その髪型にしたら似合うのかどうかは、髪を切る前からある程度見込みはつくはず。そして、あからさまにこの人にはこの髪型が似合わないと思ったら、そうはしないだろう。`}</p>
      <p>{`言ってみればここでお客さんの方は、本当に星野源みたいな髪型にして欲しいと願っているわけではない可能性がある。髪をいい感じにしたくて、そこで思いついた言葉が「星野源みたいな髪型にしてください」なんじゃなかろうか。いや、それはもちろん本当にそうかは分からない。だから、美容師さんはお客さんと会話するだろう。そして、この流れはごく自然な仕事の流れであろう。`}</p>
      <p>{`CSSの話に戻ると、ここで、ハイこれねとデザインカンプ渡されて、言われたとおりにしますね〜というのは、そういうのを求められているケースも多いとは思うけれども、そこでCSSを突っ込んで書いている者として気になることがあっても一言も発せず仕事をこなし、自分の仕事の場合は言われた通りに実装することだけと求められている…… などと考えるのは、このThe Business of Expertiseという本からすると、Expertise（専門のスキル）を行使する仕事ではない。`}</p>
      <h2 {...{
        "id": "expertiseを行使するべきか"
      }}>{`Expertiseを行使するべきか`}</h2>
      <p>{`OKOK、髪を切ってもらうのに、色々相談に乗ってくれる美容院も良い。でも自分は1,000円カットで良い。それで間に合っているし特に不満はない。CSSを書くのも同様で、別にそんなルールがどうだとか、質のいい堅牢性の高い云々のCSSを書けなくても、自分の仕事的には間に合っている。これはそうとも言える。`}</p>
      <p>{`だったらどういう仕事の仕方を選ぶか？`}</p>
      <ul>
        <li parentName="ul">{`A: 美容院で6,000円で髪を切る`}</li>
        <li parentName="ul">{`B: 1,000円カットの店で髪を切る`}</li>
      </ul>
      <p>{`この二択だったら私はAで働きたいね！って、まぁ2つ並べたらなるだろうが、この2つはどう違う？ 例えばホゲホゲ美容専門学校みたいなところを卒業した人が、この2つの店それぞれの求人に応募したらどうなる？ おそらく、BよりもAの方が狭き門だろう。いきなりAには採用されないかもしれない。（その業界のことは良くは知らないので想像だけど）`}</p>
      <p>{`なぜAで採用されないのか。端的に言うと、AではBよりもExpertiseが求められているから。専門性の高い知識と経験を求められているから。自分の髪にそこまで気を使っていない人は、Aのお店にいってもただ高いだけなので、Bを選ぶはず。しかし、Aのお店に行く人は、Bのお店に無いものを求めている。そこに6,000円を払っている。Aの代わりをBはできない。A is better than B？ しかし、Aの存在は、Bの存在を否定するわけではない。実際にこの2つは両立している。ただ、ここにあるのは、`}</p>
      <ul>
        <li parentName="ul">{`代替の効かない仕事は高い値段で売れる`}</li>
        <li parentName="ul">{`代替の効く仕事は高い値段で売れない`}</li>
      </ul>
      <p>{`という事実。CSSの話に戻ると、Aの仕事をしようとBの仕事をしようと自由だが、そういう価値の差がある。`}</p>
      <h2 {...{
        "id": "6000円の美容室で髪を切る仕事があなたにはできるのか"
      }}>{`6,000円の美容室で髪を切る仕事があなたにはできるのか`}</h2>
      <p>{`なるほどなるほど、じゃあ高く自分の仕事を売りましょうと。しかしね、そんなに高い値段でCSSを書きますとか言ったら、仕事がなくなっちゃうんですよ！となるかもしれない。それはそうなるかもしれない。重要なのは、6,000円の美容室のように、自分の仕事の値段を高くした場合に、それでお客さんが引き続きいるのだろうかという点であろうと思う。`}</p>
      <p>{`ここがズバリThe Business of Expertiseなのである。そこで依頼する側としては、「えーそんなに高いなら他に頼むわーー」ってなる。`}<a parentName="p" {...{
          "href": "/notes/2022-02-03-css-value/"
        }}>{`前回書いたように`}</a>{`、そこで自分の仕事の価値を示せなければ、それは他に頼んだほうが安上がりだし、そうするのが自然。なので、自身のExpertiseを示す必要が出る。`}</p>
      <p>{`この本には、Right positioningが重要だと書いてある。このpositioningという言葉の意味については`}<a parentName="p" {...{
          "href": "/notes/2022-02-01-tight-positioning/"
        }}>{`この前`}</a>{`書いた。正しいポジショニングができると、その仕事をコントロール出来る立場になる。コントロールなどと強いことを意識しなくてもいい。選ばれる様になるにはどうすればよいか考えよと書かれている。`}</p>
      <p>{`では、そのように選ばれる様になったときに、何ができるようになるか？ どうクライアントに臨むべきか？ ここでポイントなのが、選ばれるようになろうがなかろうが、「あんたのところは高いから他に頼むことにするわ」と、クライアントは決めることが出来るという事実。これがクライアントと仕事を請ける側の力関係として、クライアント側にある最大の力であると。`}</p>
      <p>{`そう、他に頼んじゃうじゃないですかと。しかし、仕事を受ける側にも武器があるという。むしろそれしか武器がないが、それが最大の武器というのがあり、それが何かというと「仕事を受けないこと」だという。そう、「ウチは6,000円でやってるんで、6,000円じゃないと髪は切らないんです」と言う、シンプルにそれだけのこと。それでも頼む必要があればそこには頼むし、これを可能にするのがExpertise！ということなんだそうです。`}</p>
      <p>{`ちなみに、ウチはそういう感じじゃないから、1,000円カットの方針でやっていくわというのが、無難で安泰のようにも感じられるかもしれないが、そこにはそこで「他に代替が聞いてしまうゆえに、あえて選ぶ必要が無い」ことになり、これが加速していくと、安く安く売っていくことになってしまう可能性があることは認識しなければならない。`}</p>
    </Body>
    <Outro mdxType="Outro">
      <p>{`みたいなことが書いてあるのを読んで、なるほどなーーー。って自分はなったんだけど、`}<a parentName="p" {...{
          "href": "/notes/2022-02-03-css-value/"
        }}>{`前回書いたように`}</a>{`、CSSを書くという単体では確かにそういうのを出していくのは難しそうに感じる。とは言え、はじめに書いた、ハイ、言われたとおりにやりますねという（気になることがあるけどまいっかーー頼まれてるだけだから知らないしまいっかーー！という心の声が聞こえてくるような）姿勢は、他に代替が効いてしまう方向まっしぐらなので、ウチはそうでは無いんだ！色々考慮して実装しているんだ！と考えているのであれば、値段をあげてみてはどうか？などと誰にともなく感じるのだった。`}</p>
      <p>{`価値のある仕事をしていきましょう👍`}</p>
    </Outro>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      